html {
    font-size: 1rem;
}

body {
    overflow-x: hidden;
    overflow-y: auto;
    background-color: var(--surface-ground);
    font-family: var(--font-family);
    font-weight: normal;
    color: var(--text-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
